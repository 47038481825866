<template>
  <v-card>
    <v-card-title>サインアウト</v-card-title>
    <v-card-text>ご利用いただきありがとうございます</v-card-text>
  </v-card>
</template>

<script>
import { firebase } from '@/services/firebase'
import { mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations(['setAdmin', 'setUId', 'setSignInDone'])
  },
  mounted () {
    this.setSignInDone(false)
    this.setAdmin(null)
    this.setUId('')
    firebase.auth().signOut()
    this.$router.replace('/')
  }
}
</script>
